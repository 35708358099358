export default {
  methods: {
    resetQueryParams() {
      delete this.$query.orderKey;
      delete this.$query.orderValue;
    },
    handleSort(orderKey, _orderState) {
      // todo: null state fix
      const currentQuery = this.$route.query;
      let nextValue = 'asc';

      if (currentQuery.orderValue === 'asc') {
        nextValue = 'desc';
      }
      if (currentQuery.orderValue === 'desc') {
        nextValue = null;
      }
      // order key eşit değilse sıfırla
      if (currentQuery.orderKey !== orderKey) {
        nextValue = 'asc';
      }

      if (!nextValue) {
        this.changeRoute({ orderKey: null, orderValue: null }); // null verirsen null verdiklerini siler
      } else {
        this.changeRoute({ orderKey, orderValue: nextValue });
      }
    },
    changeRoute(query, { reset, replace } = {}) {
      if (reset) {
        this.resetQueryParams();
      }
      const newPath = { query: { ...this.$query, ...query } };
      let cleanQuery = { query: {} };
      //null ise sil
      Object.keys(newPath.query).forEach(function(key) {
        if (newPath.query[key] !== null) {
          cleanQuery.query[key] = newPath.query[key];
        }
      });
      // router.push ya da router.replace
      const method = replace ? 'replace' : 'push';
      this.$router[method](cleanQuery);
    }
  }
};
