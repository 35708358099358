var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-button",
        {
          staticClass: "tab-btn",
          class: { active: _vm.$query.tab === "topic" },
          attrs: {
            color: "dark",
            type: _vm.$query.tab === "topic" ? "filled" : "line"
          },
          on: {
            click: function($event) {
              return _vm.handleTabClick("topic")
            }
          }
        },
        [_vm._v("\n    Başlık\n  ")]
      ),
      _c(
        "vs-button",
        {
          staticClass: "tab-btn",
          class: { active: _vm.$query.tab === "entry" },
          attrs: {
            color: "dark",
            type: _vm.$query.tab === "entry" ? "filled" : "line"
          },
          on: {
            click: function($event) {
              return _vm.handleTabClick("entry")
            }
          }
        },
        [_vm._v("\n    Dio\n  ")]
      ),
      _c(
        "vs-button",
        {
          staticClass: "tab-btn",
          class: { active: _vm.$query.tab === "user" },
          attrs: {
            color: "dark",
            type: _vm.$query.tab === "user" ? "filled" : "line"
          },
          on: {
            click: function($event) {
              return _vm.handleTabClick("user")
            }
          }
        },
        [_vm._v("\n    Kullanıcı\n  ")]
      ),
      _c(
        "div",
        { staticClass: "complaint-table mt-10" },
        [
          _vm.$query.tab === "entry"
            ? _c("ComplaintListEntry", {
                attrs: { list: _vm.list },
                on: { goto: _vm.handleEntryGoto, go: _vm.handleEntryGotoNew }
              })
            : _vm._e(),
          _vm.$query.tab === "topic"
            ? _c("ComplaintListTopic", {
                attrs: { list: _vm.list },
                on: { goto: _vm.handleTopicGoto, go: _vm.handleTopicGotoNew }
              })
            : _vm._e(),
          _vm.$query.tab === "user"
            ? _c("ComplaintListUser", {
                attrs: { list: _vm.list },
                on: { goto: _vm.handleUserGoto, go: _vm.handleUserGotoNew }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "con-pagination-table vs-table--pagination" },
        [
          _c("vs-pagination", {
            attrs: { total: _vm.getTotalPages },
            model: {
              value: _vm.page,
              callback: function($$v) {
                _vm.page = $$v
              },
              expression: "page"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }