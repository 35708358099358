var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-table",
        {
          attrs: {
            stripe: "",
            multiple: false,
            sst: true,
            "max-items": 50,
            data: _vm.list,
            noDataText: ""
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var data = ref.data
                return [
                  _c(
                    "tbody",
                    _vm._l(data, function(tr, indextr) {
                      return _c(
                        "vs-tr",
                        { key: indextr, attrs: { data: tr } },
                        [
                          _c("vs-td", { attrs: { id: "user" } }, [
                            _c(
                              "div",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.complaintDetail(
                                      $event,
                                      tr.id,
                                      tr.data.onedio_user_id
                                    )
                                  }
                                }
                              },
                              [_vm._v(_vm._s(tr.data.username))]
                            )
                          ]),
                          _c("vs-td", { attrs: { id: "total_count" } }, [
                            _c(
                              "div",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.complaintDetail(
                                      $event,
                                      tr.id,
                                      tr.data.onedio_user_id
                                    )
                                  }
                                }
                              },
                              [_vm._v(_vm._s(tr.total_count))]
                            )
                          ]),
                          _c("vs-td", { attrs: { id: "date" } }, [
                            _c("p", { staticClass: "font-medium w-24" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("moment")(
                                      tr.createDate,
                                      "DD-MM-YYYY"
                                    )
                                  ) +
                                  "\n              "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("moment")(tr.createDate, "HH:mm")
                                  ) +
                                  "\n            "
                              )
                            ])
                          ]),
                          _c("vs-td", { attrs: { id: "actions" } }, [
                            _c(
                              "div",
                              { staticClass: "flex justify-end" },
                              [
                                _c(
                                  "vx-tooltip",
                                  { attrs: { text: "İşlemler" } },
                                  [
                                    _c("vs-button", {
                                      staticClass: "btn btn--action",
                                      attrs: {
                                        color: "secondary",
                                        size: "small",
                                        type: "gradient",
                                        icon: "open_in_browser"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleContentLink(
                                            $event,
                                            tr.data.onedio_user_id
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "vx-tooltip",
                                  { attrs: { text: "Şikayetleri Gör" } },
                                  [
                                    _c("vs-button", {
                                      staticClass: "btn btn--action",
                                      attrs: {
                                        color: "primary",
                                        size: "small",
                                        type: "gradient",
                                        icon: "view_list"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleGoTo(
                                            $event,
                                            tr.data.onedio_user_id
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    }),
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.selected,
            callback: function($$v) {
              _vm.selected = $$v
            },
            expression: "selected"
          }
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", { attrs: { "sort-key": "user" } }, [
                _vm._v("Kullanıcı")
              ]),
              _c(
                "vs-th",
                {
                  attrs: { "sort-key": "total" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.handleSort("total_count", "asc")
                    }
                  }
                },
                [
                  _vm._v("\n        Şikayet Sayısı\n        "),
                  _vm.$store.state.route.query.orderKey === "total_count" &&
                  _vm.$store.state.route.query.orderValue === "asc"
                    ? _c(
                        "span",
                        [_c("vs-icon", { attrs: { icon: "expand_less" } })],
                        1
                      )
                    : _vm._e(),
                  _vm.$store.state.route.query.orderKey === "total_count" &&
                  _vm.$store.state.route.query.orderValue === "desc"
                    ? _c(
                        "span",
                        [_c("vs-icon", { attrs: { icon: "expand_more" } })],
                        1
                      )
                    : _vm._e()
                ]
              ),
              _c(
                "vs-th",
                {
                  attrs: { "sort-key": "createDate" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.handleSort("date", "asc")
                    }
                  }
                },
                [
                  _vm._v("\n        Oluşturulma Tarihi\n        "),
                  _vm.$store.state.route.query.orderKey === "date" &&
                  _vm.$store.state.route.query.orderValue === "asc"
                    ? _c(
                        "span",
                        [_c("vs-icon", { attrs: { icon: "expand_less" } })],
                        1
                      )
                    : _vm._e(),
                  _vm.$store.state.route.query.orderKey === "date" &&
                  _vm.$store.state.route.query.orderValue === "desc"
                    ? _c(
                        "span",
                        [_c("vs-icon", { attrs: { icon: "expand_more" } })],
                        1
                      )
                    : _vm._e()
                ]
              ),
              _c("vs-th", { staticClass: "flex justify-end" })
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }