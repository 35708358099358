<template>
  <div>
    <vs-table stripe :multiple="false" v-model="selected" :sst="true" :max-items="50" :data="list" noDataText="">
      <template slot="thead">
        <vs-th sort-key="topic">Başlık</vs-th>
        <vs-th sort-key="total" @click.native="handleSort('total_count', 'asc')">
          Şikayet Sayısı
          <span v-if="$store.state.route.query.orderKey === 'total_count' && $store.state.route.query.orderValue === 'asc'">
            <vs-icon icon="expand_less"></vs-icon>
          </span>
          <span v-if="$store.state.route.query.orderKey === 'total_count' && $store.state.route.query.orderValue === 'desc'">
            <vs-icon icon="expand_more"></vs-icon>
          </span>
        </vs-th>
        <vs-th sort-key="createDate" @click.native="handleSort('date', 'asc')">
          Oluşturulma Tarihi
          <span v-if="$store.state.route.query.orderKey === 'date' && $store.state.route.query.orderValue === 'asc'">
            <vs-icon icon="expand_less"></vs-icon>
          </span>
          <span v-if="$store.state.route.query.orderKey === 'date' && $store.state.route.query.orderValue === 'desc'">
            <vs-icon icon="expand_more"></vs-icon>
          </span>
        </vs-th>
        <vs-th class="flex justify-end"></vs-th>
      </template>
      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td id="topic">
              <div @click="complaintDetail($event, tr.id, tr.data.id)">{{ tr.data.topic }}</div>
            </vs-td>

            <vs-td id="total_count">
              <div @click="complaintDetail($event, tr.id, tr.data.id)">{{ tr.total_count }}</div>
            </vs-td>

            <vs-td id="createDate">
              <p class="font-medium w-24">
                {{ tr.createDate | moment('DD-MM-YYYY') }}
                <br />
                {{ tr.createDate | moment('HH:mm') }}
              </p>
            </vs-td>
            <vs-td id="actions">
              <div class="flex justify-end">
                <vx-tooltip text="İşlemler">
                  <vs-button
                    @click="handleContentLink($event, tr.data.id)"
                    color="secondary"
                    size="small"
                    class="btn btn--action"
                    type="gradient"
                    icon="open_in_browser"
                  ></vs-button>
                </vx-tooltip>
                <vx-tooltip text="Sil">
                  <vs-button
                    @click="(complaintMessageDialog = true), deleteEntry($event, tr.data.topic, tr.data.id, tr.data.userId)"
                    color="danger"
                    size="small"
                    class="btn btn--action"
                    type="gradient"
                    icon="delete"
                  ></vs-button>
                </vx-tooltip>
                <vx-tooltip text="Şikayetleri Gör">
                  <vs-button
                    @click="handleGoTo($event, tr.data.id)"
                    color="primary"
                    size="small"
                    class="btn btn--action"
                    type="gradient"
                    icon="view_list"
                  ></vs-button>
                </vx-tooltip>
              </div>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <popup-complaint-send-message
      :user="selected.user"
      :is-active="complaintMessageDialog"
      @close="complaintMessageDialog = false"
      :topic="topic"
      :id="id"
      :userId="userId"
      :tab="tab"
    />
  </div>
</template>

<script>
import PopupComplaintSendMessage from '@/components/popups/PopupComplaintSendMessage';
import { mapActions } from 'vuex';
import querySort from '@/mixins/querySort';
export default {
  mixins: [querySort],
  getters: {},
  props: ['list'],
  components: { PopupComplaintSendMessage },
  data: () => ({
    topic: {},
    id: '',
    selected: [],
    complaintMessageDialog: false,
    userId: '',
    tab: 'topic'
  }),
  methods: {
    ...mapActions('complaint', { getList: 'getFilterList' }),
    handleContentLink($event, id) {
      $event.stopPropagation();
      this.$router.push({ name: 'topic', query: { id } });
    },
    handleGoTo($event, id) {
      $event.stopPropagation();
      const payload = { id };
      this.$emit('goto', payload);
    },
    async deleteEntry($event, topic, id, userId) {
      this.topic = topic;
      this.id = id;
      this.userId = userId;
      $event.stopPropagation();
      const payload = { id };
      this.$emit('action', { action: 'deleteEntry', payload });
    },
    async complaintDetail($event, id, userId) {
      $event.stopPropagation();
      const payload = { id, userId };
      this.$emit('go', payload);
    }
  }
};
</script>
