<template>
  <div>
    <vs-button
      v-bind:class="{ active: $query.tab === 'topic' }"
      color="dark"
      :type="$query.tab === 'topic' ? 'filled' : 'line'"
      @click="handleTabClick('topic')"
      class="tab-btn"
    >
      Başlık
    </vs-button>
    <vs-button
      v-bind:class="{ active: $query.tab === 'entry' }"
      color="dark"
      :type="$query.tab === 'entry' ? 'filled' : 'line'"
      @click="handleTabClick('entry')"
      class="tab-btn"
    >
      Dio
    </vs-button>

    <vs-button
      v-bind:class="{ active: $query.tab === 'user' }"
      color="dark"
      :type="$query.tab === 'user' ? 'filled' : 'line'"
      @click="handleTabClick('user')"
      class="tab-btn"
    >
      Kullanıcı
    </vs-button>
    <div class="complaint-table mt-10">
      <ComplaintListEntry v-if="$query.tab === 'entry'" :list="list" @goto="handleEntryGoto" @go="handleEntryGotoNew" />
      <ComplaintListTopic v-if="$query.tab === 'topic'" :list="list" @goto="handleTopicGoto" @go="handleTopicGotoNew" />
      <ComplaintListUser v-if="$query.tab === 'user'" :list="list" @goto="handleUserGoto" @go="handleUserGotoNew" />
    </div>
    <div class="con-pagination-table vs-table--pagination">
      <vs-pagination :total="getTotalPages" v-model="page"></vs-pagination>
    </div>
  </div>
</template>

<script>
import ComplaintListUser from '@/components/complaint/ComplaintListUser.vue';
import ComplaintListEntry from '@/components/complaint/ComplaintListEntry.vue';
import ComplaintListTopic from '@/components/complaint/ComplaintListTopic.vue';
import { usePagination, useQueryParams, usePageRefresh } from '@/mixins';
import { mapActions, mapGetters } from 'vuex';
export default {
  components: { ComplaintListEntry, ComplaintListTopic, ComplaintListUser },
  mixins: [usePagination({ moduleName: 'complaint' }), useQueryParams, usePageRefresh],
  computed: {
    ...mapGetters('complaint', ['list'])
  },
  methods: {
    ...mapActions('complaint', ['getList']),
    resetQueryParams() {
      this.$query.page = 1;
      delete this.$query.search;
      delete this.$query.tab;
    },
    setDefaultQueryParams() {
      if (this.$query.hasOwnProperty('search')) return;
      if (!this.$query.hasOwnProperty('tab') || !this.$query.hasOwnProperty('page') || !this.$query.hasOwnProperty('perPage')) {
        const queryParams = { ...this.getPaginationParams, ...this.$query };
        this.$router.replace({ query: { ...queryParams, tab: 'entry' } });
      }
    },
    changeRoute(query, { reset, replace }) {
      if (reset) {
        this.resetQueryParams();
      }
      const newPath = { query: { ...this.$query, ...query } };
      const method = replace ? 'replace' : 'push';
      this.$router[method](newPath);
    },

    filterList(type) {
      this.$vs.loading();
      this.getList(type, this.fetchParams).finally(() => {
        this.$vs.loading.close();
      });
    },

    handleTabClick(type) {
      this.changeRoute({ tab: type }, { reset: true });
    },

    handleEntryGoto({ id }) {
      this.resetQueryParams();
      this.$router.push({ name: 'complaints-detail', query: { ...this.$query, id, type: 'entry' } });
    },

    handleUserGoto({ id }) {
      this.resetQueryParams();
      this.$router.push({ name: 'complaints-detail', query: { ...this.$query, id, type: 'user' } });
    },
    handleUserGotoNew({ id, userId }) {
      this.resetQueryParams();
      this.$router.push({ name: 'complaint-detail', query: { id, type: 'user', userId } });
    },
    handleTopicGotoNew({ id, userId }) {
      this.resetQueryParams();
      this.$router.push({ name: 'complaint-detail', query: { id, type: 'topic', userId } });
    },
    handleEntryGotoNew({ id, userId }) {
      this.resetQueryParams();
      this.$router.push({ name: 'complaint-detail', query: { id, type: 'entry', userId } });
    },

    handleTopicGoto({ id }) {
      this.resetQueryParams();
      this.$router.push({ name: 'complaints-detail', query: { ...this.$query, id, type: 'topic' } });
    },

    async fetchData() {
      this.setDefaultQueryParams();
      this.setPagination(this.$query);
      if (this.$query.hasOwnProperty('search') || this.$query.hasOwnProperty('id')) {
        await this.filterList();
      }
      if (this.$query.hasOwnProperty('tab')) {
        await this.filterList(this.$query.tab);
      }
    }
  },
  async created() {
    this.fetchData();
  }
};
</script>

<style>
.tab-btn + .tab-btn {
  margin: 5px;
}
</style>
