<template>
  <div>
    <vs-table stripe :multiple="false" v-model="selected" :sst="true" :max-items="50" :data="list" noDataText="">
      <template slot="thead">
        <vs-th sort-key="dıo">Dio</vs-th>
        <vs-th sort-key="total" @click.native="handleSort('total_count', 'asc')">
          Şikayet Sayısı
          <span v-if="$store.state.route.query.orderKey === 'total_count' && $store.state.route.query.orderValue === 'asc'">
            <vs-icon icon="expand_less"></vs-icon>
          </span>
          <span v-if="$store.state.route.query.orderKey === 'total_count' && $store.state.route.query.orderValue === 'desc'">
            <vs-icon icon="expand_more"></vs-icon>
          </span>
        </vs-th>
        <vs-th sort-key="date" @click.native="handleSort('date', 'asc')">
          Oluşturulma Tarihi

          <span v-if="$store.state.route.query.orderKey === 'date' && $store.state.route.query.orderValue === 'asc'">
            <vs-icon icon="expand_less"></vs-icon>
          </span>
          <span v-if="$store.state.route.query.orderKey === 'date' && $store.state.route.query.orderValue === 'desc'">
            <vs-icon icon="expand_more"></vs-icon>
          </span>
        </vs-th>
        <vs-th class="flex justify-end"></vs-th>
      </template>
      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td id="dıo" class="dio__text max-w-sm overflow-auto min-w-64">
              <div id="dio-title" v-if="tr.data.topic">
                <strong id="entrytopic">
                  <div @click="complaintDetail($event, tr.id, tr.data.id)">{{ tr.data.topic.topic }}</div>
                </strong>
              </div>
              <div id="entryhtml" class="mt-3 whitespace-pre-wrap " v-text="tr.data.entry.entryData"></div>
              <div class="flex break-word my-2" v-if="tr.data.entry.entryImageUrls && tr.data.entry.entryImageUrls.length">
                <div v-for="(img, index) in tr.data.entry.entryImageUrls" :key="index" class="w-1/5">
                  <img :src="getImageUrl(img, '75x75')" class="rounded image-entry pl-0 p-2" />
                </div>
              </div>
            </vs-td>

            <vs-td id="total">
              <div @click="complaintDetail($event, tr.id, tr.data.id)">{{ tr.total_count }}</div>
            </vs-td>

            <vs-td id="date">
              <p class="font-medium w-24">
                {{ tr.createDate | moment('DD-MM-YYYY') }}
                <br />
                {{ tr.createDate | moment('HH:mm') }}
              </p>
            </vs-td>

            <vs-td id="actions">
              <div class="flex justify-end">
                <vx-tooltip text="Sil">
                  <vs-button
                    @click="
                      (complaintMessageDialog = true),
                        deleteEntry($event, tr.data.topic, tr.data.id, tr.data.userId, tr.data.entry.entryData)
                    "
                    color="danger"
                    size="small"
                    class="btn btn--action"
                    type="gradient"
                    icon="delete"
                  ></vs-button>
                </vx-tooltip>
                <vx-tooltip text="İşlemler">
                  <vs-button
                    @click="handleContentLink($event, tr.data.id)"
                    color="secondary"
                    size="small"
                    class="btn btn--action"
                    type="gradient"
                    icon="open_in_browser"
                  ></vs-button>
                </vx-tooltip>
                <vx-tooltip text="Şikayetleri Gör">
                  <vs-button
                    @click="handleGoTo($event, tr.data.id)"
                    color="primary"
                    size="small"
                    class="btn btn--action"
                    type="gradient"
                    icon="view_list"
                  ></vs-button>
                </vx-tooltip>
              </div>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <popup-complaint-send-message
      :user="selected.user"
      :is-active="complaintMessageDialog"
      @close="complaintMessageDialog = false"
      :topic="topic"
      :id="id"
      :userId="userId"
      :tab="tab"
      :entry="entry"
    />
  </div>
</template>

<script>
import { useQueryParams } from '@/mixins';
import { generateImageUrl } from '@/utils/imageHash';
import PopupComplaintSendMessage from '@/components/popups/PopupComplaintSendMessage';
import { mapActions } from 'vuex';
import querySort from '@/mixins/querySort';
export default {
  mixins: [useQueryParams, querySort],
  props: ['list'],
  data: () => ({
    selected: [],
    topic: {},
    id: '',
    complaintMessageDialog: false,
    userId: '',
    tab: 'entry',
    entry: ''
  }),
  components: { PopupComplaintSendMessage },
  methods: {
    ...mapActions('complaint', { getList: 'getFilterList' }),

    async deleteEntry($event, topic, id, userId, entry) {
      this.topic = topic;
      this.id = id;
      this.userId = userId;
      this.entry = entry;
      $event.stopPropagation();
      const payload = { id };
      this.$emit('action', { action: 'deleteEntry', payload });
    },
    handleGoTo($event, id) {
      $event.stopPropagation();
      const payload = { id };
      this.$emit('goto', payload);
    },
    handleContentLink($event, id) {
      $event.stopPropagation();
      this.$router.push({ name: 'entry', query: { id } });
    },
    getImageUrl(image, size = '300x0') {
      let params = {
        size: size,
        quality: '80',
        imageUrl: image,
        gifToImage: true
      };
      var newUrl = generateImageUrl(params);
      return newUrl;
    },
    async complaintDetail($event, id, userId) {
      $event.stopPropagation();
      const payload = { id, userId };
      this.$emit('go', payload);
    }
  }
};
</script>
